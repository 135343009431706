import Resource from "./resource.js";
export class WhoAmI extends Resource {
    constructor(client) {
        super(client, {
            path: "/admin/{realm}/console",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
    find = this.makeRequest({
        method: "GET",
        path: "/whoami",
    });
}
