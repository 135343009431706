import Resource from "./resource.js";
export class ServerInfo extends Resource {
    constructor(client) {
        super(client, {
            path: "/admin/serverinfo",
            getBaseUrl: () => client.baseUrl,
        });
    }
    find = this.makeRequest({
        method: "GET",
        path: "/",
    });
}
